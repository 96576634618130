import React, { useEffect, useState } from 'react'
import ReactGA from "react-ga4";
import styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import { Logo, ButtonPrimary, Footer } from '@components'
import { VerificationInput } from './VerificationInput'

import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import {
    $email,
    $isNumberLogin,
    $isReqPassword,
    $phoneInput,
    $verificationCode,
    emailHandler,
    isNumberLoginEvent,
    reqPasswordHandler,
} from '../../features/sign-in-form/model/model'
import { useUnit } from 'effector-react'
import $api from '../../http'
import { userHandler } from '../../features/app/model'
import toast from 'react-hot-toast'
import NumberInput from './NumberInput'
import { makeToast } from '../../shared/ui/toast'
import { useTranslation } from 'react-i18next'
import { getStoredGclid} from '../../utils/gclid';

export const Auth: React.FC = () => {
    const navigate = useNavigate()
    const numberInputVal = useUnit($phoneInput)
    const isNumberLogin = useUnit($isNumberLogin)
    const isReqPassword = useUnit($isReqPassword)
    const code = useUnit($verificationCode)
    const email = useUnit($email)
    const phoneNumber = useUnit($phoneInput)
    const gclid = getStoredGclid();
    const [seconds, setSeconds] = useState(59)

    useEffect(() => {
        if (seconds > 0 && isReqPassword) {
            setTimeout(() => setSeconds(seconds - 1), 1000)
        } else {
            // retryRequestHandler(true)
        }
    }, [seconds, isReqPassword])

    const privacyLinkClickHandler = () => navigate('/terms/privacy')

    const termsAndConditionsClickHandler = () => navigate('/terms/conditions')

    const authHandler = () => {
        $api.api
            .authByEmailControllerSendEmailOtp({ email })
            .catch((err) => console.log(err))
    }

    const checkPassword = () => {
        $api.api
            .authByEmailControllerVerifyEmailOtp({ email, code, gclid, })
            .then((res) => {
                // @ts-ignore
                if(res.data.data!.isNew) {
                    ReactGA.event({
                        category: "CompleteRegistration",
                        action: "CompleteRegistration",
                        label: "CompleteRegistration",
                        value: 0,
                        nonInteraction: true,
                        transport: "xhr",
                    });
                }

                localStorage.setItem('token', res.data.data!.accessToken)
                localStorage.setItem('refresh', res.data.data!.refreshToken)
                navigate('/')
                window.location.reload()

                $api.api.usersControllerGetMe().then((res) => {
                    if (res.data.data) {
                        userHandler(res.data.data)
                    }
                })
            })
            .catch((err) =>
                makeToast(
                    'error',
                    'An error has occurred on the page. Try again or check the data'
                )
            )
    }

    const numberAuth = () => {
        if (numberInputVal.length > 8) {
            $api.api
                .authByPhoneControllerSendPhoneOtp({ phone: numberInputVal })
                .then((res) => makeToast('success', t('auth.toast.success')))
                .catch((err) => makeToast('error', t('auth.toast.error')))
        }
    }

    const checkNumberPassword = () => {
        $api.api
            .authByPhoneControllerVerifyPhoneOtp({
                phone: numberInputVal,
                code: code,
                gclid: gclid,
            })
            .then((res) => {
                // @ts-ignore
                if(res.data.data!.isNew) {
                    ReactGA.event({
                        category: "CompleteRegistration",
                        action: "CompleteRegistration",
                        label: "CompleteRegistration",
                        value: 0,
                        nonInteraction: true,
                        transport: "xhr",
                    });
                }

                localStorage.setItem('token', res.data.data!.accessToken)
                localStorage.setItem('refresh', res.data.data!.refreshToken)
                navigate('/')
                window.location.reload()

                $api.api.usersControllerGetMe().then((res) => {
                    if (res.data.data) {
                        userHandler(res.data.data)
                    }
                })
            })
            .catch((err) => toast.error(t('auth.toast.error')))
    }

    const send = () => {
        const length = email.length > 10 || phoneNumber.length > 10

        if (length) {
            if (!isReqPassword) {
                if (!isNumberLogin) {
                    authHandler()
                    reqPasswordHandler(true)
                } else {
                    numberAuth()
                    reqPasswordHandler(true)
                }
            } else {
                if (!isNumberLogin) checkPassword()
                else checkNumberPassword()
            }
        } else {
            makeToast(
                'error',
                'It is necessary to fill in the required fields'
            )
        }
    }

    const { t } = useTranslation()

    return (
        <>
            <div className={styles.Auth}>
                <div className={styles.Auth__backgroundElement} />
                <div className={`${styles.Auth__content} app-container`}>
                    <Logo />
                    <div className={styles.Auth__container}>
                        <div className={styles.Auth__wrapper}>
                            {!isReqPassword && !isNumberLogin && (
                                <>
                                    <p className={styles.Auth__textMargin}>
                                        {t('auth.title')}
                                    </p>
                                    <small className={styles.Auth__textMargin}>
                                        {t('auth.enterEmail')}
                                    </small>{' '}
                                    <input
                                        maxLength={34}
                                        className={styles.Auth__input}
                                        placeholder="Example@email.com"
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                            emailHandler(e.target.value)
                                        }
                                    />
                                    <small
                                        className={styles.Auth__textMargin}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => isNumberLoginEvent(true)}
                                    >
                                        {t('auth.phone')}
                                    </small>
                                </>
                            )}

                            {isReqPassword && !isNumberLogin && (
                                <>
                                    <p className={styles.Auth__textMargin}>
                                        {t('auth.title')}
                                    </p>{' '}
                                    <small className={styles.Auth__textMargin}>
                                        {t('auth.enterCode')}
                                    </small>
                                    <VerificationInput />
                                    <div
                                        className={
                                            styles.Auth__dividerContainer
                                        }
                                    >
                                        {seconds !== 0 ? (
                                            <small>
                                                {t('auth.haventRecieved')} (0:
                                                {seconds < 10
                                                    ? `0${seconds}`
                                                    : seconds}
                                                )
                                            </small>
                                        ) : (
                                            <small
                                                onClick={() => {
                                                    authHandler()
                                                    setSeconds(59)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {t('auth.retry')}
                                            </small>
                                        )}
                                    </div>
                                </>
                            )}

                            {!isReqPassword && isNumberLogin && (
                                <>
                                    <p className={styles.Auth__textMargin}>
                                        {t('auth.title')}
                                    </p>
                                    <small className={styles.Auth__textMargin}>
                                        {t('auth.enterPhone')}
                                    </small>{' '}
                                    <NumberInput />
                                    <small
                                        className={styles.Auth__textMargin}
                                        onClick={() =>
                                            isNumberLoginEvent(false)
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {t('auth.email')}
                                    </small>
                                </>
                            )}
                            {isReqPassword && isNumberLogin && (
                                <>
                                    <p className={styles.Auth__textMargin}>
                                        {t('auth.title')}
                                    </p>{' '}
                                    <small className={styles.Auth__textMargin}>
                                        {t('auth.enterCode')}
                                    </small>
                                    <VerificationInput />
                                    <div
                                        className={
                                            styles.Auth__dividerContainer
                                        }
                                    >
                                        {seconds !== 0 ? (
                                            <small>
                                                {t('auth.haventRecieved')} (0:
                                                {seconds < 10
                                                    ? `0${seconds}`
                                                    : seconds}
                                                )
                                            </small>
                                        ) : (
                                            <small
                                                onClick={() => {
                                                    numberAuth()
                                                    setSeconds(59)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {t('auth.retry')}
                                            </small>
                                        )}
                                    </div>
                                </>
                            )}

                            <ButtonPrimary
                                className={styles.Auth__button}
                                colorScheme="orange"
                                textAlign="center"
                                onClick={send}
                            >
                                {t('auth.signIn')}
                            </ButtonPrimary>
                            <div className={styles.Auth__dividerContainer}>
                                <div />
                                <small>{t('auth.continueWith')}</small>
                                <div />
                            </div>
                            <GoogleLogin
                                width={'100%'}
                                onSuccess={(credentialResponse) => {
                                    if (credentialResponse.credential) {
                                        const gclid = getStoredGclid(); // Get the stored GCLID
                                        const state = btoa(JSON.stringify({ gclid }));
                                        console.log('Using GCLID for Google OAuth:', gclid); // Log the GCLID being used

                                        $api.api
                                            .googleOauthControllerGoogleAuthRedirect({
                                                token: credentialResponse.credential,
                                                state: state,
                                            })
                                            .then((res) => {
                                                // @ts-ignore
                                                if(res.data.data!.isNew) {
                                                    ReactGA.event({
                                                        category: "CompleteRegistration",
                                                        action: "CompleteRegistration",
                                                        label: "CompleteRegistration",
                                                        value: 0,
                                                        nonInteraction: true,
                                                        transport: "xhr",
                                                    });
                                                }

                                                localStorage.setItem('token', res.data.data!.accessToken)
                                                localStorage.setItem('refresh', res.data.data!.refreshToken)
                                                navigate('/')
                                                window.location.reload()
                                            })
                                            .catch((error) => {
                                                console.error('Google OAuth error:', error);
                                                makeToast('error', t('auth.googleLoginError'));
                                            });
                                    }
                                }}
                                onError={() => {
                                    console.log('Google Login Error');
                                    makeToast('error', t('auth.googleLoginError'));
                                }}
                            />
                            <div className={styles.Auth__bottomCaption}>
                                {t('auth.byContinuing')}{' '}
                                <span onClick={termsAndConditionsClickHandler}>
                                    {t('auth.terms')}
                                </span>{' '}
                                {t('auth.and')}{' '}
                                <span onClick={privacyLinkClickHandler}>
                                    {t('auth.privacy')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.AuthFooter}>
                <Footer />
            </div>
        </>
    )
}
