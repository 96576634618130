import $api from '../../http'

export const humanizeQuery = async (text: string, isEnhancedModel: boolean) => {
    try {
        const res = await $api.api.textsControllerMakeUnique({ 
            text,
            isRehumanize: false,
            isEnhancedModel
        })
        return res.data.data?.responses[0].text
    } catch (error) {
        console.log(error)
    }
}


export const rehumanizeQuery = async (text: string, isEnhancedModel: boolean) => {
    try {
        const res = await $api.api.textsControllerMakeUnique({ 
            text, 
            isRehumanize: true,
            isEnhancedModel
        })
        return res.data.data?.responses[0].text
    } catch (error) {
        console.log(error)
    }
}

export const detectAiQuery = async (text: string | undefined) => {
    try {
        if (!text) return
        const res =
            await $api.api.textsPublicControllerGetUniquenessPercentages({
                text,
            })
        return res.data.data?.responses
    } catch (error) {
        console.log(error)
    }
}
