import React from 'react';
import styles from './style.module.scss';

interface SaleBadgeProps {
  message: string;
}

export const SaleBadge: React.FC<SaleBadgeProps> = ({ message }) => {
  return (
    <div className={styles.SaleBadge}>
      <span className={styles.SaleBadge__text}>{message}</span>
    </div>
  );
};