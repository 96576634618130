import React from 'react'
import styles from '../../style.module.scss'

export const TermsAndConditions: React.FC = () => {
    return (
        <div className={`${styles.TermsAndPrivicyPages} app-container`}>
            <h2>Terms and Conditions</h2>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    1. Introduction
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        {' '}
                        1.1. Description: Welcome to (HumanizeAI), a
                        state-of-the-art paraphrasing and content rewriting tool
                        powered by artificial intelligence. ShadowGPT (HumanizeAI) is
                        designed to help users create unique and high-quality
                        content efficiently.
                    </p>
                    <p>
                        1.2. Scope: These Terms and Conditions ("Terms") govern
                        your access to and use of the ShadowGPT (HumanizeAI) tool and its
                        associated services (collectively, the "Services"), a
                        service provided by Iskra agency AB (“Company”, "we",
                        "us", "our").". By using the Services, you agree to be
                        bound by these Terms.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    2. Acceptance of Terms and Conditions
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        {' '}
                        2.1. Agreement: By accessing and using the Iskra agency
                        AB Services, you acknowledge and agree to be bound by
                        these Terms. If you do not agree to these Terms, you
                        must not access or use the Services.
                    </p>
                    <p>
                        2.2. Changes: ShadowGPT (HumanizeAI) reserves the right to modify,
                        update, or revise these Terms at any time without prior
                        notice. Your continued use of the Services after any
                        changes to the Terms constitutes your acceptance of the
                        updated Terms.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    3. User Accounts and Registration
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        {' '}
                        3.1. Creation: To access certain features of the
                        Services, you may be required to create a user account.
                        When creating an account, you must provide accurate and
                        complete information and meet any eligibility criteria.
                        ShadowGPT (HumanizeAI) reserves the right to refuse registration or
                        cancel an account at its sole discretion.
                    </p>
                    <p>
                        3.2. Security: You are responsible for maintaining the
                        confidentiality of your account information, including
                        your password, and for any activities that occur under
                        your account. You agree to notify ShadowGPT (HumanizeAI) immediately
                        of any unauthorized access or use of your account or any
                        other security breaches.
                    </p>
                    <p>
                        3.3. Termination: ShadowGPT (HumanizeAI) may suspend or terminate
                        your account and access to the Services if you violate
                        these Terms, engage in any activities that are harmful
                        to ShadowGPT (HumanizeAI) or its users, or for any other reason at
                        ShadowGPT (HumanizeAI)'s sole discretion. You may also terminate your
                        account at any time by contacting ShadowGPT (HumanizeAI)'s customer
                        support.
                    </p>
                    <p>
                        3.4 You agree to only create one User Account and not to
                        register as a user under multiple personas. It is also
                        your responsibility to keep the information associated
                        with your User Account up to date and to maintain the
                        confidentiality, security and control of any information
                        or devices used to access your User Account.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    4. Account Sharing and Usage
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        4.1. Individual Use: Each account created on ShadowGPT (HumanizeAI)
                        is intended for individual use only. Sharing your
                        account with others is strictly prohibited. Each user
                        must create their own account and use the Services
                        individually.
                    </p>
                    <p>
                        4.2. Account Sharing Detection: ShadowGPT (HumanizeAI) utilizes
                        advanced technology to detect patterns indicative of
                        account sharing. If account sharing is detected, it is
                        grounds for immediate suspension or termination of your
                        account, at the sole discretion of ShadowGPT (HumanizeAI).
                    </p>
                    <p>
                        4.3. Consequences of Account Sharing: Account sharing
                        not only compromises the security and integrity of your
                        own account, but it may also adversely impact the
                        Services and infringe upon the rights of other users. In
                        addition to account suspension or termination, ShadowGPT (HumanizeAI)
                        reserves the right to take any other legal or technical
                        measures it deems necessary.
                    </p>
                    <p>
                        4.4. Appeal: If you believe that your account has been
                        unfairly suspended or terminated due to alleged account
                        sharing, you may appeal the decision by contacting
                        ShadowGPT (HumanizeAI)'s customer support with a detailed explanation
                        of the situation.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    5. Intellectual Property Rights
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        5.1. Ownership: All intellectual property rights in the
                        ShadowGPT (HumanizeAI) tool, its content, and the Services are owned
                        by ShadowGPT (HumanizeAI) or its licensors. You acknowledge that you
                        have no right, title, or interest in or to any
                        intellectual property rights associated with the
                        Services, except as granted in these Terms.
                    </p>
                    <p>
                        5.2. Limited License: ShadowGPT (HumanizeAI) grants you a limited,
                        non-exclusive, non-transferable, and revocable license
                        to access and use the Services for personal and
                        commercial purposes, subject to your compliance with
                        these Terms.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    6. User Content
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        6.1. Ownership: You retain all rights and ownership of
                        the content you submit, create, or generate using the
                        Services ("User Content"). ShadowGPT (HumanizeAI) does not claim any
                        ownership rights in your User Content.
                    </p>
                    <p>
                        6.2. Responsibility: You are solely responsible for your
                        User Content and any consequences resulting from its use
                        or publication. ShadowGPT (HumanizeAI) does not endorse or take
                        responsibility for any User Content submitted through
                        the Services.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    7. Prohibited Uses
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        7.1. Restrictions: You agree not to use the Services for
                        any unlawful, harmful, or malicious purposes, or in any
                        way that violates these Terms or infringes upon the
                        rights of others.
                    </p>
                    <p>
                        7.2. Examples: Examples of prohibited uses include, but
                        are not limited to, submitting copyrighted or
                        plagiarized content, using the Services for cheating or
                        academic misconduct, spreading misinformation, harassing
                        or defaming others, or attempting to gain unauthorized
                        access to ShadowGPT (HumanizeAI) systems or user accounts.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    8. Limitation of Liability
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        8.1. No Warranty: The Services are provided on an "as
                        is" and "as available" basis, without any warranties of
                        any kind, either express or implied. ShadowGPT (HumanizeAI) does not
                        guarantee that the Services will meet your requirements
                        or be uninterrupted, secure, or error-free.
                    </p>
                    <p>
                        8.2. Liability: To the maximum extent permitted by law,
                        ShadowGPT (HumanizeAI) and its affiliates, employees, and agents
                        shall not be liable for any direct, indirect,
                        incidental, or consequential damages arising out of or
                        in connection with your use of or inability to use the
                        Services, even if advised of the possibility of such
                        damages.
                    </p>
                    <p>
                        8.3. AI Detection: ShadowGPT (HumanizeAI) does not guarantee that the
                        content generated by the tool will be undetectable by AI
                        detectors or plagiarism-checking software. You assume
                        full responsibility for any consequences resulting from
                        the use of the generated content.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    9. Governing Law and Jurisdiction
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        9.1. Governing Law: These Terms and any disputes or
                        claims arising out of or in connection with them or
                        their subject matter or formation (including
                        non-contractual disputes or claims) shall be governed by
                        and construed in accordance with the laws of Sweden.
                    </p>
                    <p>
                        9.2. Jurisdiction: You agree that the courts of Sweden
                        shall have exclusive jurisdiction to settle any dispute
                        or claim arising out of or in connection with these
                        Terms or their subject matter or formation (including
                        non-contractual disputes or claims).
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    10. Miscellaneous
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        10.1. Severability: If any provision of these Terms is
                        held to be invalid or unenforceable by a court, the
                        remaining provisions will remain in full force and
                        effect.
                    </p>
                    <p>
                        10.2. Entire Agreement: These Terms constitute the
                        entire agreement between you and ShadowGPT (HumanizeAI) concerning
                        the Services and supersede any prior agreements,
                        understandings, or representations, whether oral or
                        written.
                    </p>
                    <p>
                        10.3. No Waiver: ShadowGPT (HumanizeAI)'s failure to enforce any
                        provision of these Terms shall not be deemed a waiver of
                        such provision nor the right to enforce it.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    11. Payments, Subscriptions, and Refunds
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        11.1. Plans: ShadowGPT (HumanizeAI) offers various subscription plans
                        with different features and pricing. Detailed
                        information on available plans and associated fees can
                        be found on our website.
                    </p>
                    <p>
                        11.2. Processing: Payments for subscriptions are
                        processed through secure third-party payment providers.
                        Accepted payment methods include credit cards, debit
                        cards, and other payment methods as specified on our
                        website.
                    </p>
                    <p>
                        11.3. Cancellation and Automatic Renewal: You may cancel
                        your subscription at any time. If you do not cancel your
                        subscription before the renewal date, it will be
                        automatically renewed, and you will be charged for the
                        next billing period. Refunds for cancelled subscriptions
                        will be processed in accordance with our refund policy,
                        which can be found on our website.
                    </p>
                    <p>
                        11.4. Offers: ShadowGPT (HumanizeAI) may offer free trials or
                        promotional offers from time to time. The terms and
                        conditions of these offers will be specified at the time
                        of the promotion and may be subject to change.
                    </p>
                    <p>
                        11.5. Price and Plan Changes: ShadowGPT (HumanizeAI) reserves the
                        right at any time to modify, change, or discontinue any
                        of our subscription plans, including fees, features,
                        conditions, or the availability of free trials, without
                        prior notice. Such changes will only affect future
                        transactions or terms of service, and will not be
                        retroactive. ShadowGPT (HumanizeAI) is not liable to you or any third
                        party for any modification, price change, suspension or
                        discontinuance of the Service or any part thereof. Your
                        continued use of the Services after such changes
                        signifies your acceptance of the new terms. It is your
                        responsibility to regularly check the website or other
                        communications for any such updates.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    12. Refund Policy
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        12.1. No Refunds for Used Services: Refunds will not be
                        provided for any subscription plan if the user has
                        already used the Service during the paid period.
                    </p>
                    <p>
                        12.2. Free Plan: ShadowGPT (HumanizeAI) offers a free plan that
                        allows users to try the Service before purchasing a
                        subscription. By using the free plan, users can evaluate
                        the suitability of the Service before committing to a
                        paid plan.
                    </p>
                    <p>
                        12.3. Refund Requests: To request a refund, users must
                        contact ShadowGPT (HumanizeAI)'s customer support within the
                        specified timeframe. Refund requests will be evaluated
                        on a case-by-case basis and granted at ShadowGPT (HumanizeAI)'s sole
                        discretion.
                    </p>
                    <p>
                    12.4. Service Credit Refunds for Non-Humanized Content: If a user claims that the processed text is not adequately humanized, ShadowGPT (HumanizeAI) will refund the cost of the humanization in service credits (requests), provided that the mathematically average AI content detection score of at least four detectors is more than 60%. Users must submit their claim to customer support with the necessary evidence of the detection scores. Each claim will be evaluated on a case-by-case basis, and service credits will be issued at ShadowGPT (HumanizeAI)'s sole discretion.
                    </p>
                    <p>
                        12.5. Exceptions: We encourage all users to be mindful
                        of their subscription renewal dates and make necessary
                        adjustments to avoid unwanted charges. Iskra agency AB
                        shall not be held responsible for any charges incurred
                        due to the user's failure to unsubscribe.
                    </p>

                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    13. Severability
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        If any provision of these Terms is held to be invalid or
                        unenforceable by a court, the remaining provisions will
                        remain in full force and effect.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    14. Indemnification
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        You agree to indemnify, defend, and hold harmless
                        ShadowGPT (HumanizeAI) and its affiliates, employees, agents, and
                        licensors from and against any claims, liabilities,
                        damages, judgments, awards, losses, costs, expenses, or
                        fees (including reasonable attorneys' fees) arising out
                        of or relating to your violation of these Terms or your
                        use of the Service.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    15. Entire Agreement
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        These Terms constitute the entire agreement between you
                        and Iskra agency AB concerning the Services and
                        supersede any prior agreements, understandings, or
                        representations, whether oral or written.
                    </p>
                </div>
            </div>
            <div>
                <h3 className={styles.TermsAndPrivicyPages__title}>
                    16. Contact Us
                </h3>
                <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
                    <p>
                        If you have any questions or concerns regarding these
                        Terms or the Services, please contact us through the
                        provided contact information on our website or by email
                        at support@humanizeai.now We will make every effort to
                        address your inquiries in a timely and efficient manner.
                    </p>
                </div>
            </div>
        </div>
    )
}
