import React from 'react'
import style from './style.module.scss'

import CopyLeaks from './assets/CopyLeaks.svg'
import GPTZero from './assets/GPTZero.svg'
import ZeroGPT from './assets/ZeroGPT.svg'
import Sapling from './assets/Sapling.svg'
import { ReactComponent as CheckIcon } from './assets/CheckIcon.svg'
import { ReactComponent as AiDetectedIcon } from './assets/AiDetected.svg'
import { ReactComponent as UncertainIcon } from './assets/Uncertain.svg'
import { ReactComponent as LoaderIcon } from './assets/Loader.svg'
import { useTranslation } from 'react-i18next'

type ServiceName = 'COPYLEAKS' | 'SAPLING' | 'GPT_ZERO' | 'ZERO_GPT' | 'OPEN_AI' | 'INTERNAL_UNIQUE';

interface DataItem {
    serviceName: ServiceName;
    text: number;
    isError: boolean;
}

type Props = {
    loading?: boolean;
    bottomAiResultBadge?: boolean;
    data?: DataItem[];
}

const serviceOrder: ServiceName[] = ['COPYLEAKS', 'SAPLING', 'GPT_ZERO', 'ZERO_GPT'];

const imgs: Record<ServiceName, string> = {
    COPYLEAKS: CopyLeaks,
    SAPLING: Sapling,
    GPT_ZERO: GPTZero,
    ZERO_GPT: ZeroGPT,
    OPEN_AI: '',
    INTERNAL_UNIQUE: '',
};

const getBarColor = (percent: number) => {
    let color = 'rgba(242, 26, 52, 1)'

    if (percent < 60) {
        color = 'rgba(253, 137, 7, 1)'
    }

    if (percent < 30) {
        color = 'rgba(0, 172, 59, 1)'
    }

    return color
}

export const TextDetectionResult: React.FC<Props> = ({
    loading = false,
    bottomAiResultBadge = true,
    data = [],
}) => {
    const { t } = useTranslation();

    const renderItems = () => {
        const sortedData = serviceOrder
            .map(serviceName => data.find(item => item.serviceName === serviceName))
            .filter((item): item is DataItem => item !== undefined);

        return (
            <div className={style.TextDetectionResult}>
                <div className={style.TextDetectionResult__flexWrapper}>
                    {sortedData.map((el: DataItem) => (
                        <div
                            key={el.serviceName}
                            className={style.TextDetectionResult__indicatorContainer}
                        >
                            <div className={style.TextDetectionResult__titleContainer}>
                                <img
                                    className={style.TextDetectionResult__titleContainer_img}
                                    src={imgs[el.serviceName]}
                                    alt={el.serviceName}
                                />
                                {loading ? (
                                    <LoaderIcon className={style.LoaderAnimation} />
                                ) : (
                                    <small>
                                        {!el.isError && el.text >= 0 &&
                                            `${Math.round(el.text * 100)}%`}
                                        {!el.isError && el.text < 0 &&
                                            t('detection.paidPlan')}
                                        {el.isError && t('detection.error')}
                                    </small>
                                )}
                            </div>
                            <div className={style.TextDetectionResult__indicator}>
                                <div
                                    style={{
                                        width: `${loading
                                            ? 0
                                            : !el.isError && el.text >= 0
                                            ? Math.round(el.text * 100)
                                            : 100}%`,
                                        backgroundColor: el.text < 0
                                            ? 'grey' 
                                            : getBarColor(el.text * 100),
                                        backgroundImage: el.text < 0 
                                            ? 'linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)'
                                            : 'none',
                                        backgroundSize: '40px 40px',
                                    }}
                                    className={style.TextDetectionResult__indicator_greenBar}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const renderResultIcon = () => {
        if (data && !loading) {
            const activeResults = data.filter((result: any) => result.text >= 0 && !result.isError);
            
            if (activeResults.length === 0) {
                console.warn('No valid results to display');
                return <></>;
            }
    
            const resultSum = activeResults.reduce((sum: number, cur: any) => {
                const value = parseFloat(cur.text);
                return isNaN(value) ? sum : sum + value;
            }, 0);
    
            const resultAverage = Math.round((resultSum / activeResults.length) * 100);
    

            if (resultAverage > 75) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-35px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator}>
                            <AiDetectedIcon />
                        </div>
                        <small>{t('detection.detected')}</small>
                    </div>
                );
            }

            if (resultAverage == 50) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-35px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator}>
                            <UncertainIcon />
                        </div>
                        <small>{t('detection.uncertain')}</small>
                    </div>
                )
            }

            if (resultAverage <= 75 && resultAverage > 40) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-35px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator}>
                            <UncertainIcon />
                        </div>
                        <small>{t('detection.uncertain')}</small>
                    </div>
                );
            }

            if (resultAverage <= 40) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-35px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator_green}>
                            <CheckIcon />
                        </div>
                        <small>{t('detection.notDetected')}</small>
                    </div>
                );
            }
        }

        return <></>
    }

    return (
        <div className={style.Container}>
            {data && !loading ? (
                renderItems()
            ) : (
                <div className={style.TextDetectionResult}>
                    <div className={style.TextDetectionResult__flexWrapper}>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small></small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {/* <ZeroGPT
                                    className={
                                        style.TextDetectionResult__titleContainer_img
                                    }
                                /> */}
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small>{0}%</small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.TextDetectionResult__flexWrapper}>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {/* <CopyLeaks
                                    className={
                                        style.TextDetectionResult__titleContainer_img
                                    }
                                /> */}
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small></small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {/* <Sapling
                                    fill="#fff"
                                    className={
                                        style.TextDetectionResult__titleContainer_img
                                    }
                                /> */}
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small></small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {bottomAiResultBadge && (
                <div className={style.ResultIconContainer}>
                    <div className={style.ResultIconContainer__indicator}>
                        <CheckIcon />
                    </div>
                    <small>{t('detection.notDetected')}</small>
                </div>
            )}
            {!bottomAiResultBadge && !loading && renderResultIcon()}
        </div>
    )
}
