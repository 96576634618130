import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, checked, onCheckedChange, ...props }, ref) => {
  return (
    <SwitchPrimitives.Root
      className={className}
      style={{
        width: '40px',
        height: '22px',
        backgroundColor: checked ? '#FF5830' : '#383b3f',
        borderRadius: '9999px',
        position: 'relative',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        cursor: 'pointer',
        border: 'none',
        padding: '0',
        transition: 'all 0.2s ease-in-out',
        boxShadow: checked 
          ? '0 0 10px rgba(255, 88, 48, 0.5), inset 0 1px 2px rgba(0,0,0,0.1)'
          : '0 0 5px rgba(56, 59, 63, 0.3), inset 0 1px 2px rgba(0,0,0,0.1)',
      }}
      checked={checked}
      onCheckedChange={onCheckedChange}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        style={{
          display: 'block',
          width: '18px',
          height: '18px',
          backgroundColor: 'white',
          borderRadius: '9999px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2), 0 0 2px rgba(0,0,0,0.1)',
          transition: 'all 0.2s ease-in-out',
          transform: checked ? 'translateX(20px)' : 'translateX(2px)',
          willChange: 'transform',
        }}
      />
    </SwitchPrimitives.Root>
  )
})

Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }