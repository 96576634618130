export function getGclid(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('gclid');
  }
  
  export function storeGclidInLocalStorage(gclid: string): void {
    localStorage.setItem('gclid', gclid);
  }
  
  export function getStoredGclid(): string | undefined {
    return localStorage.getItem('gclid') || undefined;
  }