/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LanguageResponseDto {
  code: string;
  name: string;
  /** @format uri */
  icon?: string;
}

export interface CurrencyResponseDto {
  /** @example "USD" */
  code: string;
  /** @example "United States Dollar" */
  name: string;
  /** @example "$" */
  symbol: string;
}

export interface UserSubscriptionResponseDto {
  /** @format uuid */
  userSubscriptionId: string;
  /** @format uuid */
  subscriptionId: string;
  title: string;
  description: string;
  maxWords: number;
  isActive?: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  expiredAt?: string;
  totalRequests?: number;
  requestsLeft?: number;
}

export interface UserDetailedResponseDto {
  userId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  googleId?: string;
  /**
   * @format byte
   * @example "U3dhZ2dlciByb2Nrcw=="
   */
  image?: string;
  role: "USER" | "ADMIN";
  status: "ACTIVE" | "DEACTIVATE" | "PAUSED";
  language: LanguageResponseDto;
  currency: CurrencyResponseDto;
  subscription: UserSubscriptionResponseDto;
  country: string;
  /** @format date-time */
  createdAt: string;
}

export interface BodyUpdateUserSettingsDto {
  languageCode?: string;
  currencyCode?: "SEK" | "USD" | "EUR";
  /**
   * @minLength 3
   * @maxLength 50
   */
  country?: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  firstName?: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  lastName?: string;
}

export interface BodyCreateUserDto {
  /**
   * @minLength 4
   * @maxLength 25
   */
  phone?: string;
  /** @format email */
  email?: string;
  status: "ACTIVE" | "DEACTIVATE" | "PAUSED";
  languageCode: string;
  currencyCode: "SEK" | "USD" | "EUR";
  startTrial?: boolean;
  isAdmin?: boolean;
  /** @format uuid */
  subscriptionId?: string;
}

export interface PaginatedMetaDocumented {
  /** Number of items per page */
  itemsPerPage: number;
  /** Total number of items */
  totalItems: number;
  /** Current requested page */
  currentPage: number;
  /** Total number of pages */
  totalPages: number;
  /** Sorting by columns */
  sortBy?: (string | "ASC" | "DESC")[][];
  /** Search by fields */
  searchBy?: string[];
  /** Search term */
  search?: string;
  /** List of selected fields */
  select?: string[];
  /** Filters that applied to the query */
  filter?: object;
}

export interface PaginatedLinksDocumented {
  /** Link to first page */
  first?: string;
  /** Link to previous page */
  previous?: string;
  /** Link to current page */
  current?: string;
  /** Link to next page */
  next?: string;
  /** Link to last page */
  last?: string;
}

export interface PaginatedDocumented {
  /** Array of entities */
  data: object[];
  /** Pagination Metadata */
  meta: PaginatedMetaDocumented;
  /** Links to pages */
  links: PaginatedLinksDocumented;
}

export interface LoginUserResponseDto {
  accessToken: string;
  refreshToken: string;
}

export interface BodyGoogleLoginDto {
  token: string;
  state?: string
}

export interface SendOtpResponseDto {
  /** @format date-type */
  validUntil: string;
}

export interface BodySendPhoneOtpDto {
  /**
   * @minLength 4
   * @maxLength 25
   */
  phone: string;
  throwIfNotExist?: boolean;
}

export interface BodyVerifyPhoneOtpDto {
  /**
   * @minLength 4
   * @maxLength 25
   */
  phone: string;
  code: string;
  languageCode?: string;
  currencyCode?: "SEK" | "USD" | "EUR";
  country?: string;
  gclid?: string;
}

export interface BodySendEmailOtpDto {
  /** @format email */
  email: string;
  throwIfNotExist?: boolean;
}

export interface BodyVerifyEmailOtpDto {
  /** @format email */
  email: string;
  code: string;
  languageCode?: string;
  currencyCode?: "SEK" | "USD" | "EUR";
  country?: string;
  gclid?: string;
}

export interface BodyRefreshDto {
  /** @format jwt */
  refreshToken: string;
}

export interface BodyLogoutDto {
  /** @format jwt */
  refreshToken: string;
}

export interface PaymentResponseDto {
  amount: number;
  currency: CurrencyResponseDto;
  status: "PROCESSING" | "COMPLETED" | "CANCELLED" | "FAILED" | "AUTHORISED" | "AUTHENTICATED";
  isRegularPayment: boolean;
  /** @format date-time */
  updatedAt?: string;
}

export interface PaymentAccountResponseDto {
  service: "revolut";
  last4?: string;
  funding?: string;
  brand?: string;
  /** @format date-time */
  createdAt: string;
}

export interface BodyGetStatisticsFiltersDto {
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
  requestIds?: string[];
  operations?: ("DETECT" | "UNIQUE")[];
  subOperations?: ("MAKE" | "GENERATE" | "DETECT")[];
  isError?: boolean;
  userIds?: string[];
  subscriptionIds?: string[];
  isSubscriptionActive?: boolean;
  languageCodes?: string[];
  userStatuses?: ("ACTIVE" | "DEACTIVATE" | "PAUSED")[];
  /** @example "1 month" */
  subscriptionDurations?: string[];
  /** @example "USA" */
  countries?: string[];
}

export interface BodyGetStatisticsGroupsDto {
  month?: boolean;
  user?: boolean;
  operation?: boolean;
  subOperation?: boolean;
  error?: boolean;
  subscription?: boolean;
  subscriptionStatus?: boolean;
  subscriptionDuration?: boolean;
  language?: boolean;
  country?: boolean;
}

export interface BodyGetStatisticsDto {
  filters?: BodyGetStatisticsFiltersDto;
  groups?: BodyGetStatisticsGroupsDto;
}

export interface SubscribeResponseDto {
  token: string;
}

export interface CreateUserSubscriptionBodyDto {
  /** @format uuid */
  subscriptionId: string;
  paymentService: "revolut";
  isRegularPayment: boolean;
}

export interface SubscriptionResponseDto {
  /** @format uuid */
  subscriptionId: string;
  title?: string;
  period: "day" | "week" | "month" | "year";
  description?: string;
  features?: string[];
  amount?: number;
  type: "TRIAL" | "COMMON";
  currency?: CurrencyResponseDto;
  isPricingPage: boolean;
}

export interface SubscriptionTranslationResponseDto {
  /** @format uuid */
  translationId: string;
  title: string;
  description: string;
  features?: string[];
  language: LanguageResponseDto;
}

export interface SubscriptionAmountResponseDto {
  /** @format uuid */
  amountId: string;
  amount: number;
  currency?: CurrencyResponseDto;
}

export interface IntervalDto {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export interface SubscriptionAdminResponseDto {
  /** @format uuid */
  subscriptionId: string;
  translations: SubscriptionTranslationResponseDto[];
  amounts: SubscriptionAmountResponseDto[];
  type: "TRIAL" | "COMMON";
  requests: number;
  maxWords: number;
  duration: IntervalDto;
}

export interface BodyCreateSubscriptionDto {
  duration: IntervalDto;
  /** @min 1 */
  requests: number;
  /** @min 1 */
  maxWords: number;
  type: "TRIAL" | "COMMON";
}

export interface BodyUpdateSubscriptionDto {
  duration?: IntervalDto;
  /** @min 1 */
  requests?: number;
  /** @min 1 */
  maxWords?: number;
  type?: "TRIAL" | "COMMON";
}

export interface BodyCreateSubscriptionAmountDto {
  /** @format uuid */
  subscriptionId: string;
  currencyCode: "SEK" | "USD" | "EUR";
  amount: number;
}

export interface BodyUpdateSubscriptionAmountDto {
  currencyCode?: "SEK" | "USD" | "EUR";
  amount?: number;
}

export interface BodyCreateSubscriptionTranslationDto {
  /** @format uuid */
  subscriptionId: string;
  languageCode: string;
  title: string;
  description: string;
  features: string[];
}

export interface BodyUpdateSubscriptionTranslationDto {
  languageCode?: string;
  title?: string;
  description?: string;
  features?: string[];
}

export interface UserResponseDto {
  userId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  googleId?: string;
  /**
   * @format byte
   * @example "U3dhZ2dlciByb2Nrcw=="
   */
  image?: string;
  role: "USER" | "ADMIN";
  status: "ACTIVE" | "DEACTIVATE" | "PAUSED";
  country: string;
  /** @format date-time */
  createdAt: string;
}

export interface UserSubscriptionAdminResponseDto {
  userSubscription: UserSubscriptionResponseDto;
  user: UserResponseDto;
}

export interface CreateUserSubscriptionAdminBodyDto {
  /** @format uuid */
  subscriptionId: string;
  /** @format uuid */
  userId: string;
}

export interface UpdateUserSubscriptionAdminBodyDto {
  /** @format uuid */
  subscriptionId: string;
  requestsLeft: number;
}

export interface ChatMessageResponseDto {
  /** @format uuid */
  id: string;
  text?: string;
  isError: boolean;
  serviceName: "COPYLEAKS" | "GPT_ZERO" | "SAPLING" | "ZERO_GPT" | "OPEN_AI" | "INTERNAL_UNIQUE";
  /** @format date-time */
  createdAt: string;
}

export interface MessageRequestResponseDto {
  /** @format uuid */
  requestId: string;
  /** @format uuid */
  chatId?: string;
  chatRequestNumber?: number;
  request: string;
  responses: ChatMessageResponseDto[];
  operation: "DETECT" | "UNIQUE";
  subOperation: "MAKE" | "GENERATE" | "DETECT";
  /** @format date-time */
  createdAt: string;
}

export interface BodyMakeUniqueTextDto {
  /** @minLength 2 */
  text: string;
  /** @format uuid */
  chatId?: string;
  /** @description Indicates whether this is a rehumanization request */
  isRehumanize?: boolean;
  /** @description Indicates whether this is a Enhanced model */
  isEnhancedModel?: boolean;
}

export interface BodyGenerateUniqueTextDto {
  /** @minLength 2 */
  text: string;
  /** @format uuid */
  chatId?: string;
  /** @description Indicates whether this is a rehumanization request */
  isRehumanize?: boolean;
}

export interface AdminMessageRequestResponseDto {
  /** @format uuid */
  requestId: string;
  /** @format uuid */
  chatId?: string;
  chatRequestNumber?: number;
  request: string;
  responses: ChatMessageResponseDto[];
  operation: "DETECT" | "UNIQUE";
  subOperation: "MAKE" | "GENERATE" | "DETECT";
  /** @format date-time */
  createdAt: string;
  user: UserDetailedResponseDto;
  /** @format date-time */
  deletedAt: string;
}

export interface BodyGetTextUniquePercentageDto {
  /** @minLength 2 */
  text: string;
  /** @format uuid */
  chatId?: string;
}

export interface BodyCreateLanguageDto {
  /** ISO 639 language code */
  code: string;
  name: string;
  /** @format uri */
  icon?: string;
}

export interface BodyUpdateLanguageDto {
  name?: string;
  /** @format uri */
  icon?: string;
}

export interface ChatResponseDto {
  /** @format uuid */
  id: string;
  /** @format text */
  name: string;
  orderNumber?: number;
  isPined: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface BodyCreateChatDto {
  name: string;
}

export interface BodyUpdateChatDto {
  name: string;
}

export interface ChatDetailedResponseDto {
  /** @format uuid */
  id: string;
  /** @format text */
  name: string;
  orderNumber?: number;
  isPined: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  messages: MessageRequestResponseDto[];
}

export type UsersControllerUpdateMePayload = BodyUpdateUserSettingsDto & {
  /** @format binary */
  file?: File;
};

export interface UsersAdminControllerGetUsersParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by languageCode query param.
   *           <p>
   *              <b>Format: </b> filter.languageCode={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.languageCode=$not:$like:John Doe&filter.languageCode=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterLanguageCode?: string[];
  /**
   * Filter by currencyCode query param.
   *           <p>
   *              <b>Format: </b> filter.currencyCode={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.currencyCode=$not:$like:John Doe&filter.currencyCode=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterCurrencyCode?: string[];
  /**
   * Filter by firstName query param.
   *           <p>
   *              <b>Format: </b> filter.firstName={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.firstName=$not:$like:John Doe&filter.firstName=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterFirstName?: string[];
  /**
   * Filter by lastName query param.
   *           <p>
   *              <b>Format: </b> filter.lastName={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.lastName=$not:$like:John Doe&filter.lastName=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterLastName?: string[];
  /**
   * Filter by phone query param.
   *           <p>
   *              <b>Format: </b> filter.phone={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.phone=$not:$like:John Doe&filter.phone=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterPhone?: string[];
  /**
   * Filter by email query param.
   *           <p>
   *              <b>Format: </b> filter.email={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.email=$not:$like:John Doe&filter.email=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterEmail?: string[];
  /**
   * Filter by createdAt query param.
   *           <p>
   *              <b>Format: </b> filter.createdAt={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.createdAt=$not:$like:John Doe&filter.createdAt=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterCreatedAt?: string[];
  /**
   * Filter by status query param.
   *           <p>
   *              <b>Format: </b> filter.status={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.status=$not:$like:John Doe&filter.status=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterStatus?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>id</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>firstName</li>
   * <li>lastName</li>
   * <li>status</li></ul>
   *
   */
  sortBy?: (
    | "id:ASC"
    | "id:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "firstName:ASC"
    | "firstName:DESC"
    | "lastName:ASC"
    | "lastName:DESC"
    | "status:ASC"
    | "status:DESC"
  )[];
}

export interface PaymentsControllerGetPaymentsParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by currencyCode query param.
   *           <p>
   *              <b>Format: </b> filter.currencyCode={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.currencyCode=$not:$like:John Doe&filter.currencyCode=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterCurrencyCode?: string[];
  /**
   * Filter by status query param.
   *           <p>
   *              <b>Format: </b> filter.status={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.status=$not:$like:John Doe&filter.status=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterStatus?: string[];
  /**
   * Filter by createdAt query param.
   *           <p>
   *              <b>Format: </b> filter.createdAt={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.createdAt=$not:$like:John Doe&filter.createdAt=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li>
   * <li>$null</li></ul>
   */
  filterCreatedAt?: string[];
  /**
   * Filter by isRegularPayment query param.
   *           <p>
   *              <b>Format: </b> filter.isRegularPayment={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.isRegularPayment=$not:$like:John Doe&filter.isRegularPayment=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
   */
  filterIsRegularPayment?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>id</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>status</li></ul>
   *
   */
  sortBy?: (
    | "id:ASC"
    | "id:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "status:ASC"
    | "status:DESC"
  )[];
}

export interface SubscriptionsPublicControllerGetSubscriptionsParams {
  languageCode?: string;
  currencyCode?: "SEK" | "USD" | "EUR";
}

export interface SubscriptionsAdminControllerGetManyParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by duration query param.
   *           <p>
   *              <b>Format: </b> filter.duration={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.duration=$not:$like:John Doe&filter.duration=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterDuration?: string[];
  /**
   * Filter by requests query param.
   *           <p>
   *              <b>Format: </b> filter.requests={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.requests=$not:$like:John Doe&filter.requests=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterRequests?: string[];
  /**
   * Filter by maxWords query param.
   *           <p>
   *              <b>Format: </b> filter.maxWords={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.maxWords=$not:$like:John Doe&filter.maxWords=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterMaxWords?: string[];
  /**
   * Filter by type query param.
   *           <p>
   *              <b>Format: </b> filter.type={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.type=$not:$like:John Doe&filter.type=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterType?: string[];
  /**
   * Filter by amounts.amount query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.amount={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.amount=$not:$like:John Doe&filter.amounts.amount=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterAmountsAmount?: string[];
  /**
   * Filter by amounts.currency.code query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.currency.code={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.currency.code=$not:$like:John Doe&filter.amounts.currency.code=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterAmountsCurrencyCode?: string[];
  /**
   * Filter by amounts.currency.name query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.currency.name={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.currency.name=$not:$like:John Doe&filter.amounts.currency.name=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterAmountsCurrencyName?: string[];
  /**
   * Filter by amounts.currency.symbol query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.currency.symbol={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.currency.symbol=$not:$like:John Doe&filter.amounts.currency.symbol=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterAmountsCurrencySymbol?: string[];
  /**
   * Filter by translations.title query param.
   *           <p>
   *              <b>Format: </b> filter.translations.title={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.title=$not:$like:John Doe&filter.translations.title=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTranslationsTitle?: string[];
  /**
   * Filter by translations.description query param.
   *           <p>
   *              <b>Format: </b> filter.translations.description={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.description=$not:$like:John Doe&filter.translations.description=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTranslationsDescription?: string[];
  /**
   * Filter by translations.language.code query param.
   *           <p>
   *              <b>Format: </b> filter.translations.language.code={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.language.code=$not:$like:John Doe&filter.translations.language.code=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterTranslationsLanguageCode?: string[];
  /**
   * Filter by translations.language.name query param.
   *           <p>
   *              <b>Format: </b> filter.translations.language.name={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.language.name=$not:$like:John Doe&filter.translations.language.name=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTranslationsLanguageName?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>id</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>duration</li>
   * <li>requests</li>
   * <li>maxWords</li>
   * <li>type</li>
   * <li>amounts.amount</li>
   * <li>amounts.currency.name</li>
   * <li>amounts.currency.code</li>
   * <li>amounts.currency.symbol</li>
   * <li>translations.title</li>
   * <li>translations.description</li>
   * <li>translations.language.code</li>
   * <li>translations.language.name</li></ul>
   *
   */
  sortBy?: (
    | "id:ASC"
    | "id:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "duration:ASC"
    | "duration:DESC"
    | "requests:ASC"
    | "requests:DESC"
    | "maxWords:ASC"
    | "maxWords:DESC"
    | "type:ASC"
    | "type:DESC"
    | "amounts.amount:ASC"
    | "amounts.amount:DESC"
    | "amounts.currency.name:ASC"
    | "amounts.currency.name:DESC"
    | "amounts.currency.code:ASC"
    | "amounts.currency.code:DESC"
    | "amounts.currency.symbol:ASC"
    | "amounts.currency.symbol:DESC"
    | "translations.title:ASC"
    | "translations.title:DESC"
    | "translations.description:ASC"
    | "translations.description:DESC"
    | "translations.language.code:ASC"
    | "translations.language.code:DESC"
    | "translations.language.name:ASC"
    | "translations.language.name:DESC"
  )[];
}

export interface SubscriptionsAmountsAdminControllerGetParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by subscriptionId query param.
   *           <p>
   *              <b>Format: </b> filter.subscriptionId={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.subscriptionId=$not:$like:John Doe&filter.subscriptionId=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterSubscriptionId?: string[];
  /**
   * Filter by amount query param.
   *           <p>
   *              <b>Format: </b> filter.amount={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amount=$not:$like:John Doe&filter.amount=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterAmount?: string[];
  /**
   * Filter by currency.code query param.
   *           <p>
   *              <b>Format: </b> filter.currency.code={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.currency.code=$not:$like:John Doe&filter.currency.code=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterCurrencyCode?: string[];
  /**
   * Filter by currency.name query param.
   *           <p>
   *              <b>Format: </b> filter.currency.name={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.currency.name=$not:$like:John Doe&filter.currency.name=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterCurrencyName?: string[];
  /**
   * Filter by currency.symbol query param.
   *           <p>
   *              <b>Format: </b> filter.currency.symbol={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.currency.symbol=$not:$like:John Doe&filter.currency.symbol=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterCurrencySymbol?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>id</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>amount</li>
   * <li>currency.code</li>
   * <li>currency.name</li>
   * <li>currency.symbol</li></ul>
   *
   */
  sortBy?: (
    | "id:ASC"
    | "id:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "amount:ASC"
    | "amount:DESC"
    | "currency.code:ASC"
    | "currency.code:DESC"
    | "currency.name:ASC"
    | "currency.name:DESC"
    | "currency.symbol:ASC"
    | "currency.symbol:DESC"
  )[];
}

export interface SubscriptionsTranslationsAdminControllerGetParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by subscriptionId query param.
   *           <p>
   *              <b>Format: </b> filter.subscriptionId={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.subscriptionId=$not:$like:John Doe&filter.subscriptionId=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterSubscriptionId?: string[];
  /**
   * Filter by title query param.
   *           <p>
   *              <b>Format: </b> filter.title={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.title=$not:$like:John Doe&filter.title=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTitle?: string[];
  /**
   * Filter by description query param.
   *           <p>
   *              <b>Format: </b> filter.description={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.description=$not:$like:John Doe&filter.description=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterDescription?: string[];
  /**
   * Filter by language.code query param.
   *           <p>
   *              <b>Format: </b> filter.language.code={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.language.code=$not:$like:John Doe&filter.language.code=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterLanguageCode?: string[];
  /**
   * Filter by language.name query param.
   *           <p>
   *              <b>Format: </b> filter.language.name={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.language.name=$not:$like:John Doe&filter.language.name=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterLanguageName?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>id</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>title</li>
   * <li>description</li>
   * <li>language.code</li>
   * <li>language.name</li></ul>
   *
   */
  sortBy?: (
    | "id:ASC"
    | "id:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "title:ASC"
    | "title:DESC"
    | "description:ASC"
    | "description:DESC"
    | "language.code:ASC"
    | "language.code:DESC"
    | "language.name:ASC"
    | "language.name:DESC"
  )[];
}

export interface UsersSubscriptionsAdminControllerGetManyParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by duration query param.
   *           <p>
   *              <b>Format: </b> filter.duration={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.duration=$not:$like:John Doe&filter.duration=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterDuration?: string[];
  /**
   * Filter by requests query param.
   *           <p>
   *              <b>Format: </b> filter.requests={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.requests=$not:$like:John Doe&filter.requests=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterRequests?: string[];
  /**
   * Filter by maxWords query param.
   *           <p>
   *              <b>Format: </b> filter.maxWords={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.maxWords=$not:$like:John Doe&filter.maxWords=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterMaxWords?: string[];
  /**
   * Filter by type query param.
   *           <p>
   *              <b>Format: </b> filter.type={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.type=$not:$like:John Doe&filter.type=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterType?: string[];
  /**
   * Filter by amounts.amount query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.amount={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.amount=$not:$like:John Doe&filter.amounts.amount=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li></ul>
   */
  filterAmountsAmount?: string[];
  /**
   * Filter by amounts.currency.code query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.currency.code={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.currency.code=$not:$like:John Doe&filter.amounts.currency.code=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterAmountsCurrencyCode?: string[];
  /**
   * Filter by amounts.currency.name query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.currency.name={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.currency.name=$not:$like:John Doe&filter.amounts.currency.name=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterAmountsCurrencyName?: string[];
  /**
   * Filter by amounts.currency.symbol query param.
   *           <p>
   *              <b>Format: </b> filter.amounts.currency.symbol={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.amounts.currency.symbol=$not:$like:John Doe&filter.amounts.currency.symbol=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterAmountsCurrencySymbol?: string[];
  /**
   * Filter by translations.title query param.
   *           <p>
   *              <b>Format: </b> filter.translations.title={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.title=$not:$like:John Doe&filter.translations.title=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTranslationsTitle?: string[];
  /**
   * Filter by translations.description query param.
   *           <p>
   *              <b>Format: </b> filter.translations.description={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.description=$not:$like:John Doe&filter.translations.description=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTranslationsDescription?: string[];
  /**
   * Filter by translations.language.code query param.
   *           <p>
   *              <b>Format: </b> filter.translations.language.code={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.language.code=$not:$like:John Doe&filter.translations.language.code=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterTranslationsLanguageCode?: string[];
  /**
   * Filter by translations.language.name query param.
   *           <p>
   *              <b>Format: </b> filter.translations.language.name={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.translations.language.name=$not:$like:John Doe&filter.translations.language.name=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterTranslationsLanguageName?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>id</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>duration</li>
   * <li>requests</li>
   * <li>maxWords</li>
   * <li>type</li>
   * <li>amounts.amount</li>
   * <li>amounts.currency.name</li>
   * <li>amounts.currency.code</li>
   * <li>amounts.currency.symbol</li>
   * <li>translations.title</li>
   * <li>translations.description</li>
   * <li>translations.language.code</li>
   * <li>translations.language.name</li></ul>
   *
   */
  sortBy?: (
    | "id:ASC"
    | "id:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "duration:ASC"
    | "duration:DESC"
    | "requests:ASC"
    | "requests:DESC"
    | "maxWords:ASC"
    | "maxWords:DESC"
    | "type:ASC"
    | "type:DESC"
    | "amounts.amount:ASC"
    | "amounts.amount:DESC"
    | "amounts.currency.name:ASC"
    | "amounts.currency.name:DESC"
    | "amounts.currency.code:ASC"
    | "amounts.currency.code:DESC"
    | "amounts.currency.symbol:ASC"
    | "amounts.currency.symbol:DESC"
    | "translations.title:ASC"
    | "translations.title:DESC"
    | "translations.description:ASC"
    | "translations.description:DESC"
    | "translations.language.code:ASC"
    | "translations.language.code:DESC"
    | "translations.language.name:ASC"
    | "translations.language.name:DESC"
  )[];
}

export interface TextsControllerGetTextsParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by request.operation query param.
   *           <p>
   *              <b>Format: </b> filter.request.operation={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.request.operation=$not:$like:John Doe&filter.request.operation=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterRequestOperation?: string[];
  /**
   * Filter by request.subOperation query param.
   *           <p>
   *              <b>Format: </b> filter.request.subOperation={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.request.subOperation=$not:$like:John Doe&filter.request.subOperation=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterRequestSubOperation?: string[];
  /**
   * Filter by request.text query param.
   *           <p>
   *              <b>Format: </b> filter.request.text={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.request.text=$not:$like:John Doe&filter.request.text=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterRequestText?: string[];
  /**
   * Filter by userResponses.response.result query param.
   *           <p>
   *              <b>Format: </b> filter.userResponses.response.result={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userResponses.response.result=$not:$like:John Doe&filter.userResponses.response.result=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterUserResponsesResponseResult?: string[];
  /**
   * Filter by userResponses.isError query param.
   *           <p>
   *              <b>Format: </b> filter.userResponses.isError={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userResponses.isError=$not:$like:John Doe&filter.userResponses.isError=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
   */
  filterUserResponsesIsError?: string[];
  /**
   * Filter by userResponses.serviceName query param.
   *           <p>
   *              <b>Format: </b> filter.userResponses.serviceName={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userResponses.serviceName=$not:$like:John Doe&filter.userResponses.serviceName=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterUserResponsesServiceName?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>request.createdAt</li>
   * <li>userId</li>
   * <li>request.text</li>
   * <li>createdAt</li>
   * <li>updatedAt</li></ul>
   *
   */
  sortBy?: (
    | "request.createdAt:ASC"
    | "request.createdAt:DESC"
    | "userId:ASC"
    | "userId:DESC"
    | "request.text:ASC"
    | "request.text:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
  )[];
}

export interface TextsAdminControllerGetManyParams {
  /**
   * Page number to retrieve.If you provide invalid value the default page number will applied
   *         <p>
   *              <b>Example: </b> 1
   *           </p>
   *         <p>
   *              <b>Default Value: </b> 1
   *           </p>
   *
   */
  page?: number;
  /**
   * Number of records per page.
   *       <p>
   *              <b>Example: </b> 20
   *           </p>
   *       <p>
   *              <b>Default Value: </b> 20
   *           </p>
   *       <p>
   *              <b>Max Value: </b> 100
   *           </p>
   *
   *       If provided value is greater than max value, max value will be applied.
   *
   */
  limit?: number;
  /**
   * Filter by id query param.
   *           <p>
   *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterId?: string[];
  /**
   * Filter by userId query param.
   *           <p>
   *              <b>Format: </b> filter.userId={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userId=$not:$like:John Doe&filter.userId=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterUserId?: string[];
  /**
   * Filter by deletedAt query param.
   *           <p>
   *              <b>Format: </b> filter.deletedAt={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.deletedAt=$not:$like:John Doe&filter.deletedAt=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li>
   * <li>$null</li></ul>
   */
  filterDeletedAt?: string[];
  /**
   * Filter by createdAt query param.
   *           <p>
   *              <b>Format: </b> filter.createdAt={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.createdAt=$not:$like:John Doe&filter.createdAt=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$gte</li>
   * <li>$gt</li>
   * <li>$lte</li>
   * <li>$lt</li>
   * <li>$btw</li>
   * <li>$null</li></ul>
   */
  filterCreatedAt?: string[];
  /**
   * Filter by user.languageCode query param.
   *           <p>
   *              <b>Format: </b> filter.user.languageCode={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.user.languageCode=$not:$like:John Doe&filter.user.languageCode=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterUserLanguageCode?: string[];
  /**
   * Filter by user.country query param.
   *           <p>
   *              <b>Format: </b> filter.user.country={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.user.country=$not:$like:John Doe&filter.user.country=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterUserCountry?: string[];
  /**
   * Filter by user.currencyCode query param.
   *           <p>
   *              <b>Format: </b> filter.user.currencyCode={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.user.currencyCode=$not:$like:John Doe&filter.user.currencyCode=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterUserCurrencyCode?: string[];
  /**
   * Filter by request.operation query param.
   *           <p>
   *              <b>Format: </b> filter.request.operation={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.request.operation=$not:$like:John Doe&filter.request.operation=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterRequestOperation?: string[];
  /**
   * Filter by request.subOperation query param.
   *           <p>
   *              <b>Format: </b> filter.request.subOperation={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.request.subOperation=$not:$like:John Doe&filter.request.subOperation=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li></ul>
   */
  filterRequestSubOperation?: string[];
  /**
   * Filter by request.text query param.
   *           <p>
   *              <b>Format: </b> filter.request.text={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.request.text=$not:$like:John Doe&filter.request.text=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterRequestText?: string[];
  /**
   * Filter by userResponses.response.result query param.
   *           <p>
   *              <b>Format: </b> filter.userResponses.response.result={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userResponses.response.result=$not:$like:John Doe&filter.userResponses.response.result=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterUserResponsesResponseResult?: string[];
  /**
   * Filter by userResponses.isError query param.
   *           <p>
   *              <b>Format: </b> filter.userResponses.isError={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userResponses.isError=$not:$like:John Doe&filter.userResponses.isError=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
   */
  filterUserResponsesIsError?: string[];
  /**
   * Filter by userResponses.serviceName query param.
   *           <p>
   *              <b>Format: </b> filter.userResponses.serviceName={$not}:OPERATION:VALUE
   *           </p>
   *           <p>
   *              <b>Example: </b> filter.userResponses.serviceName=$not:$like:John Doe&filter.userResponses.serviceName=like:John
   *           </p>
   *           <h4>Available Operations</h4><ul><li>$eq</li>
   * <li>$in</li>
   * <li>$ilike</li>
   * <li>$sw</li></ul>
   */
  filterUserResponsesServiceName?: string[];
  /**
   * Parameter to sort by.
   *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
   *       <p>
   *              <b>Format: </b> fieldName:DIRECTION
   *           </p>
   *       <p>
   *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
   *           </p>
   *       <p>
   *              <b>Default Value: </b> createdAt:DESC
   *           </p>
   *       <h4>Available Fields</h4><ul><li>request.createdAt</li>
   * <li>userId</li>
   * <li>request.text</li>
   * <li>createdAt</li>
   * <li>updatedAt</li>
   * <li>user.firstName</li>
   * <li>user.lastName</li></ul>
   *
   */
  sortBy?: (
    | "request.createdAt:ASC"
    | "request.createdAt:DESC"
    | "userId:ASC"
    | "userId:DESC"
    | "request.text:ASC"
    | "request.text:DESC"
    | "createdAt:ASC"
    | "createdAt:DESC"
    | "updatedAt:ASC"
    | "updatedAt:DESC"
    | "user.firstName:ASC"
    | "user.firstName:DESC"
    | "user.lastName:ASC"
    | "user.lastName:DESC"
  )[];
}

export interface ChatsControllerGetParams {
  /**
   * @min 0
   * @max 100
   * @default 30
   */
  limit: number;
  /**
   * @min 0
   * @default 0
   */
  offset: number;
  chatId: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title uniqueness-of-texts
 * @version 1.0.0
 * @contact
 *
 * Web Services for Unique Texts
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    /**
     * @description Update user settings
     *
     * @tags Users
     * @name UsersControllerUpdateMe
     * @summary Update user settings
     * @request PATCH:/api/v2/users/me/settings
     * @secure
     */
    usersControllerUpdateMe: (data: UsersControllerUpdateMePayload, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserDetailedResponseDto;
        },
        any
      >({
        path: `/api/v2/users/me/settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Get Me
     *
     * @tags Users
     * @name UsersControllerGetMe
     * @summary Get Me
     * @request GET:/api/v2/users/me
     * @secure
     */
    usersControllerGetMe: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserDetailedResponseDto;
        },
        any
      >({
        path: `/api/v2/users/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerDeleteMe
     * @summary Delete Me
     * @request DELETE:/api/v2/users/me
     * @secure
     */
    usersControllerDeleteMe: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v2/users/me`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Create user
     *
     * @tags Users [Admin]
     * @name UsersAdminControllerCreateUser
     * @summary Create user
     * @request POST:/api/v3/users
     * @secure
     */
    usersAdminControllerCreateUser: (data: BodyCreateUserDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserDetailedResponseDto;
        },
        any
      >({
        path: `/api/v3/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users [Admin]
     * @name UsersAdminControllerGetUsers
     * @summary Get users
     * @request GET:/api/v3/users
     * @secure
     */
    usersAdminControllerGetUsers: (query: UsersAdminControllerGetUsersParams, params: RequestParams = {}) =>
      this.http.request<
        PaginatedDocumented & {
          data?: UserDetailedResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              languageCode?: string | string[];
              currencyCode?: string | string[];
              firstName?: string | string[];
              lastName?: string | string[];
              phone?: string | string[];
              email?: string | string[];
              createdAt?: string | string[];
              status?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v3/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get user
     *
     * @tags Users [Admin]
     * @name UsersAdminControllerGetUser
     * @summary Get user
     * @request GET:/api/v3/users/{userId}
     * @secure
     */
    usersAdminControllerGetUser: (userId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserDetailedResponseDto;
        },
        any
      >({
        path: `/api/v3/users/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update user
     *
     * @tags Users [Admin]
     * @name UsersAdminControllerUpdateUser
     * @summary Update user
     * @request PATCH:/api/v3/users/{userId}
     * @secure
     */
    usersAdminControllerUpdateUser: (userId: string, data: BodyCreateUserDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserDetailedResponseDto;
        },
        any
      >({
        path: `/api/v3/users/${userId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users [Admin]
     * @name UsersAdminControllerDeleteUser
     * @summary Delete user
     * @request DELETE:/api/v3/users/{userId}
     * @secure
     */
    usersAdminControllerDeleteUser: (userId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/users/${userId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Google OAuth
     *
     * @tags Auth [google OAuth]
     * @name GoogleOauthControllerGoogleAuthRedirect
     * @summary Google OAuth
     * @request POST:/api/v1/auth/google
     * @secure
     */
    googleOauthControllerGoogleAuthRedirect: (data: BodyGoogleLoginDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LoginUserResponseDto;
        },
        any
      >({
        path: `/api/v1/auth/google`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Send user phone otp
     *
     * @tags Auth [phone]
     * @name AuthByPhoneControllerSendPhoneOtp
     * @summary Send user phone otp
     * @request POST:/api/v1/auth/phone/send-otp
     * @secure
     */
    authByPhoneControllerSendPhoneOtp: (data: BodySendPhoneOtpDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: SendOtpResponseDto;
        },
        any
      >({
        path: `/api/v1/auth/phone/send-otp`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Verify user phone otp
     *
     * @tags Auth [phone]
     * @name AuthByPhoneControllerVerifyPhoneOtp
     * @summary Verify user phone otp
     * @request POST:/api/v1/auth/phone/verify-otp
     * @secure
     */
    authByPhoneControllerVerifyPhoneOtp: (data: BodyVerifyPhoneOtpDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LoginUserResponseDto;
        },
        any
      >({
        path: `/api/v1/auth/phone/verify-otp`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Send user email otp
     *
     * @tags Auth [email]
     * @name AuthByEmailControllerSendEmailOtp
     * @summary Send user email otp
     * @request POST:/api/v1/auth/email/send-otp
     * @secure
     */
    authByEmailControllerSendEmailOtp: (data: BodySendEmailOtpDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: SendOtpResponseDto;
        },
        any
      >({
        path: `/api/v1/auth/email/send-otp`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Verify user email otp
     *
     * @tags Auth [email]
     * @name AuthByEmailControllerVerifyEmailOtp
     * @summary Verify user email otp
     * @request POST:/api/v1/auth/email/verify-otp
     * @secure
     */
    authByEmailControllerVerifyEmailOtp: (data: BodyVerifyEmailOtpDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LoginUserResponseDto;
        },
        any
      >({
        path: `/api/v1/auth/email/verify-otp`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Refresh access token
     *
     * @tags Auth
     * @name AuthControllerRefresh
     * @summary Refresh access token
     * @request POST:/api/v1/auth/refresh
     * @secure
     */
    authControllerRefresh: (data: BodyRefreshDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LoginUserResponseDto;
        },
        any
      >({
        path: `/api/v1/auth/refresh`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogout
     * @summary Delete refresh token
     * @request POST:/api/v1/auth/logout
     * @secure
     */
    authControllerLogout: (data: BodyLogoutDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/auth/logout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get my currency
     *
     * @tags Currencies
     * @name CurrenciesControllerGetMyCurrency
     * @summary Get my currency
     * @request GET:/api/v2/currencies/my
     * @secure
     */
    currenciesControllerGetMyCurrency: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: CurrencyResponseDto;
        },
        any
      >({
        path: `/api/v2/currencies/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get currencies
     *
     * @tags Currencies [PUBLIC]
     * @name CurrenciesPublicControllerGetCurrencies
     * @summary Get currencies
     * @request GET:/api/v1/currencies
     * @secure
     */
    currenciesPublicControllerGetCurrencies: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: CurrencyResponseDto[];
        },
        any
      >({
        path: `/api/v1/currencies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments [Revolut Webhook]
     * @name RevolutPaymentsControllerUpdateStatus
     * @request POST:/api/v0/payments/revolut/orders
     */
    revolutPaymentsControllerUpdateStatus: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v0/payments/revolut/orders`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentsControllerGetPayments
     * @summary Get user payments
     * @request GET:/api/v2/payments
     * @secure
     */
    paymentsControllerGetPayments: (query: PaymentsControllerGetPaymentsParams, params: RequestParams = {}) =>
      this.http.request<
        PaginatedDocumented & {
          data?: PaymentResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              currencyCode?: string | string[];
              status?: string | string[];
              createdAt?: string | string[];
              isRegularPayment?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v2/payments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get user payment account
     *
     * @tags payments
     * @name PaymentsControllerGetAccount
     * @summary Get user payment account
     * @request GET:/api/v2/payments/account
     * @secure
     */
    paymentsControllerGetAccount: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: PaymentAccountResponseDto;
        },
        any
      >({
        path: `/api/v2/payments/account`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics [ADMIN]
     * @name StatisticsAdminControllerGet
     * @request POST:/api/v3/statistics
     * @secure
     */
    statisticsAdminControllerGet: (data: BodyGetStatisticsDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/statistics`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Subscribe
     *
     * @tags Subscriptions
     * @name SubscriptionsControllerCreateSubscription
     * @summary Subscribe
     * @request POST:/api/v2/subscriptions
     * @secure
     */
    subscriptionsControllerCreateSubscription: (data: CreateUserSubscriptionBodyDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: SubscribeResponseDto;
        },
        any
      >({
        path: `/api/v2/subscriptions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get my subscriptions
     *
     * @tags Subscriptions
     * @name SubscriptionsControllerGetMySubscriptions
     * @summary Get my subscriptions
     * @request GET:/api/v2/subscriptions/my
     * @secure
     */
    subscriptionsControllerGetMySubscriptions: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserSubscriptionResponseDto[];
        },
        any
      >({
        path: `/api/v2/subscriptions/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get subscriptions
     *
     * @tags Subscriptions [Public]
     * @name SubscriptionsPublicControllerGetSubscriptions
     * @summary Get subscriptions
     * @request GET:/api/v1/subscriptions
     * @secure
     */
    subscriptionsPublicControllerGetSubscriptions: (
      query: SubscriptionsPublicControllerGetSubscriptionsParams,
      params: RequestParams = {},
    ) =>
      this.http.request<
        {
          data?: SubscriptionResponseDto[];
        },
        any
      >({
        path: `/api/v1/subscriptions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /** 
     * @description Cancel Current Subscription
     * 
     * @tags Subscriptions
     * @name SubscriptionsControllerCancelCurrentSubscription
     * @summary Cancel Current Subscription
     * @request PATCH:/api/v2/subscriptions/cancel
     * @secure
     */
    subscriptionsControllerCancelCurrentSubscription: (params: RequestParams = {}) =>
      this.http.request<
        { data?: UserSubscriptionResponseDto },
        any
      >({
        path: `/api/v2/subscriptions/cancel`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),
      
    /**
     * @description Create subscription
     *
     * @tags Subscriptions [ADMIN]
     * @name SubscriptionsAdminControllerCreate
     * @summary Create subscription
     * @request POST:/api/v3/subscriptions
     * @secure
     */
    subscriptionsAdminControllerCreate: (data: BodyCreateSubscriptionDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: SubscriptionAdminResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions [ADMIN]
     * @name SubscriptionsAdminControllerGetMany
     * @summary Get subscriptions
     * @request GET:/api/v3/subscriptions
     * @secure
     */
    subscriptionsAdminControllerGetMany: (
      query: SubscriptionsAdminControllerGetManyParams,
      params: RequestParams = {},
    ) =>
      this.http.request<
        PaginatedDocumented & {
          data?: SubscriptionAdminResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              duration?: string | string[];
              requests?: string | string[];
              maxWords?: string | string[];
              type?: string | string[];
              "amounts.amount"?: string | string[];
              "amounts.currencyCode"?: string | string[];
              "amounts.currency.name"?: string | string[];
              "amounts.currency.code"?: string | string[];
              "amounts.currency.symbol"?: string | string[];
              "translations.title"?: string | string[];
              "translations.description"?: string | string[];
              "translations.language.code"?: string | string[];
              "translations.language.name"?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v3/subscriptions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update subscription
     *
     * @tags Subscriptions [ADMIN]
     * @name SubscriptionsAdminControllerUpdate
     * @summary Update subscription
     * @request PATCH:/api/v3/subscriptions/{subscriptionId}
     * @secure
     */
    subscriptionsAdminControllerUpdate: (
      subscriptionId: string,
      data: BodyUpdateSubscriptionDto,
      params: RequestParams = {},
    ) =>
      this.http.request<
        {
          data?: SubscriptionAdminResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions/${subscriptionId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions [ADMIN]
     * @name SubscriptionsAdminControllerDelete
     * @summary Delete subscription
     * @request DELETE:/api/v3/subscriptions/{subscriptionId}
     * @secure
     */
    subscriptionsAdminControllerDelete: (subscriptionId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/subscriptions/${subscriptionId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Get subscription
     *
     * @tags Subscriptions [ADMIN]
     * @name SubscriptionsAdminControllerGet
     * @summary Get subscription
     * @request GET:/api/v3/subscriptions/{subscriptionId}
     * @secure
     */
    subscriptionsAdminControllerGet: (subscriptionId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: SubscriptionAdminResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions/${subscriptionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create subscription amount
     *
     * @tags Subscriptions amounts [ADMIN]
     * @name SubscriptionsAmountsAdminControllerCreate
     * @summary Create subscription amount
     * @request POST:/api/v3/subscriptions-amounts
     * @secure
     */
    subscriptionsAmountsAdminControllerCreate: (data: BodyCreateSubscriptionAmountDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: SubscriptionAmountResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions-amounts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions amounts [ADMIN]
     * @name SubscriptionsAmountsAdminControllerGet
     * @summary Get subscription amounts
     * @request GET:/api/v3/subscriptions-amounts
     * @secure
     */
    subscriptionsAmountsAdminControllerGet: (
      query: SubscriptionsAmountsAdminControllerGetParams,
      params: RequestParams = {},
    ) =>
      this.http.request<
        PaginatedDocumented & {
          data?: SubscriptionAmountResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              subscriptionId?: string | string[];
              amount?: string | string[];
              currencyCode?: string | string[];
              "currency.name"?: string | string[];
              "currency.code"?: string | string[];
              "currency.symbol"?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v3/subscriptions-amounts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update subscription amount
     *
     * @tags Subscriptions amounts [ADMIN]
     * @name SubscriptionsAmountsAdminControllerUpdate
     * @summary Update subscription amount
     * @request PATCH:/api/v3/subscriptions-amounts/{amountId}
     * @secure
     */
    subscriptionsAmountsAdminControllerUpdate: (
      amountId: string,
      data: BodyUpdateSubscriptionAmountDto,
      params: RequestParams = {},
    ) =>
      this.http.request<
        {
          data?: SubscriptionAmountResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions-amounts/${amountId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions amounts [ADMIN]
     * @name SubscriptionsAmountsAdminControllerDelete
     * @summary Delete subscription amount
     * @request DELETE:/api/v3/subscriptions-amounts/{amountId}
     * @secure
     */
    subscriptionsAmountsAdminControllerDelete: (amountId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/subscriptions-amounts/${amountId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Create subscription translation
     *
     * @tags Subscriptions translations [ADMIN]
     * @name SubscriptionsTranslationsAdminControllerCreate
     * @summary Create subscription translation
     * @request POST:/api/v3/subscriptions-translations
     * @secure
     */
    subscriptionsTranslationsAdminControllerCreate: (
      data: BodyCreateSubscriptionTranslationDto,
      params: RequestParams = {},
    ) =>
      this.http.request<
        {
          data?: SubscriptionTranslationResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions-translations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions translations [ADMIN]
     * @name SubscriptionsTranslationsAdminControllerGet
     * @summary Get subscription translations
     * @request GET:/api/v3/subscriptions-translations
     * @secure
     */
    subscriptionsTranslationsAdminControllerGet: (
      query: SubscriptionsTranslationsAdminControllerGetParams,
      params: RequestParams = {},
    ) =>
      this.http.request<
        PaginatedDocumented & {
          data?: SubscriptionTranslationResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              subscriptionId?: string | string[];
              title?: string | string[];
              description?: string | string[];
              "language.code"?: string | string[];
              "language.name"?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v3/subscriptions-translations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update subscription translation
     *
     * @tags Subscriptions translations [ADMIN]
     * @name SubscriptionsTranslationsAdminControllerUpdate
     * @summary Update subscription translation
     * @request PATCH:/api/v3/subscriptions-translations/{translationId}
     * @secure
     */
    subscriptionsTranslationsAdminControllerUpdate: (
      translationId: string,
      data: BodyUpdateSubscriptionTranslationDto,
      params: RequestParams = {},
    ) =>
      this.http.request<
        {
          data?: SubscriptionTranslationResponseDto;
        },
        any
      >({
        path: `/api/v3/subscriptions-translations/${translationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions translations [ADMIN]
     * @name SubscriptionsTranslationsAdminControllerDelete
     * @summary Delete subscription translation
     * @request DELETE:/api/v3/subscriptions-translations/{translationId}
     * @secure
     */
    subscriptionsTranslationsAdminControllerDelete: (translationId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/subscriptions-translations/${translationId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Create user subscription
     *
     * @tags Users Subscriptions [ADMIN]
     * @name UsersSubscriptionsAdminControllerCreate
     * @summary Create user subscription
     * @request POST:/api/v3/users-subscriptions
     * @secure
     */
    usersSubscriptionsAdminControllerCreate: (data: CreateUserSubscriptionAdminBodyDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserSubscriptionAdminResponseDto;
        },
        any
      >({
        path: `/api/v3/users-subscriptions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users Subscriptions [ADMIN]
     * @name UsersSubscriptionsAdminControllerGetMany
     * @summary Get user subscriptions
     * @request GET:/api/v3/users-subscriptions
     * @secure
     */
    usersSubscriptionsAdminControllerGetMany: (
      query: UsersSubscriptionsAdminControllerGetManyParams,
      params: RequestParams = {},
    ) =>
      this.http.request<
        PaginatedDocumented & {
          data?: UserSubscriptionAdminResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              duration?: string | string[];
              requests?: string | string[];
              maxWords?: string | string[];
              type?: string | string[];
              "amounts.amount"?: string | string[];
              "amounts.currencyCode"?: string | string[];
              "amounts.currency.name"?: string | string[];
              "amounts.currency.code"?: string | string[];
              "amounts.currency.symbol"?: string | string[];
              "translations.title"?: string | string[];
              "translations.description"?: string | string[];
              "translations.language.code"?: string | string[];
              "translations.language.name"?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v3/users-subscriptions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get user subscription
     *
     * @tags Users Subscriptions [ADMIN]
     * @name UsersSubscriptionsAdminControllerGet
     * @summary Get user subscription
     * @request GET:/api/v3/users-subscriptions/{userSubscriptionId}
     * @secure
     */
    usersSubscriptionsAdminControllerGet: (userSubscriptionId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: UserSubscriptionAdminResponseDto;
        },
        any
      >({
        path: `/api/v3/users-subscriptions/${userSubscriptionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update user subscription
     *
     * @tags Users Subscriptions [ADMIN]
     * @name UsersSubscriptionsAdminControllerUpdate
     * @summary Update user subscription
     * @request PATCH:/api/v3/users-subscriptions/{userSubscriptionId}
     * @secure
     */
    usersSubscriptionsAdminControllerUpdate: (
      userSubscriptionId: string,
      data: UpdateUserSubscriptionAdminBodyDto,
      params: RequestParams = {},
    ) =>
      this.http.request<
        {
          data?: UserSubscriptionAdminResponseDto;
        },
        any
      >({
        path: `/api/v3/users-subscriptions/${userSubscriptionId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users Subscriptions [ADMIN]
     * @name UsersSubscriptionsAdminControllerDelete
     * @summary Delete user subscriptions
     * @request DELETE:/api/v3/users-subscriptions/{userSubscriptionId}
     * @secure
     */
    usersSubscriptionsAdminControllerDelete: (userSubscriptionId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/users-subscriptions/${userSubscriptionId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Make unique text
     *
     * @tags Text
     * @name TextsControllerMakeUnique
     * @summary Make unique text
     * @request POST:/api/v2/texts/make-unique
     * @secure
     */
    textsControllerMakeUnique: (data: BodyMakeUniqueTextDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: MessageRequestResponseDto;
        },
        any
      >({
        path: `/api/v2/texts/make-unique`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Generate unique text
     *
     * @tags Text
     * @name TextsControllerGenerateUnique
     * @summary Generate unique text
     * @request POST:/api/v2/texts/generate-uniques
     * @secure
     */
    textsControllerGenerateUnique: (data: BodyGenerateUniqueTextDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: MessageRequestResponseDto;
        },
        any
      >({
        path: `/api/v2/texts/generate-uniques`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Text
     * @name TextsControllerGetTexts
     * @summary Get unique text requests history
     * @request GET:/api/v2/texts
     * @secure
     */
    textsControllerGetTexts: (query: TextsControllerGetTextsParams, params: RequestParams = {}) =>
      this.http.request<
        PaginatedDocumented & {
          data?: MessageRequestResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              "request.operation"?: string | string[];
              "request.subOperation"?: string | string[];
              "request.text"?: string | string[];
              "userResponses.response.result"?: string | string[];
              "userResponses.isError"?: string | string[];
              "userResponses.serviceName"?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v2/texts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get unique text request
     *
     * @tags Text
     * @name TextsControllerGetUniqueText
     * @summary Get unique text request
     * @request GET:/api/v2/texts/{requestId}
     * @secure
     */
    textsControllerGetUniqueText: (requestId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: MessageRequestResponseDto;
        },
        any
      >({
        path: `/api/v2/texts/${requestId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Text
     * @name TextsControllerDelete
     * @summary Delete unique text request
     * @request DELETE:/api/v2/texts/{requestId}
     * @secure
     */
    textsControllerDelete: (requestId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v2/texts/${requestId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Text [ADMIN]
     * @name TextsAdminControllerGetMany
     * @summary Get unique text requests history
     * @request GET:/api/v3/texts
     * @secure
     */
    textsAdminControllerGetMany: (query: TextsAdminControllerGetManyParams, params: RequestParams = {}) =>
      this.http.request<
        PaginatedDocumented & {
          data?: AdminMessageRequestResponseDto[];
          meta?: {
            select?: string[];
            filter?: {
              id?: string | string[];
              userId?: string | string[];
              deletedAt?: string | string[];
              createdAt?: string | string[];
              "user.languageCode"?: string | string[];
              "user.country"?: string | string[];
              "user.currencyCode"?: string | string[];
              "request.operation"?: string | string[];
              "request.subOperation"?: string | string[];
              "request.text"?: string | string[];
              "userResponses.response.result"?: string | string[];
              "userResponses.isError"?: string | string[];
              "userResponses.serviceName"?: string | string[];
            };
          };
        },
        any
      >({
        path: `/api/v3/texts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get unique text request
     *
     * @tags Text [ADMIN]
     * @name TextsAdminControllerGet
     * @summary Get unique text request
     * @request GET:/api/v3/texts/{requestId}
     * @secure
     */
    textsAdminControllerGet: (requestId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: AdminMessageRequestResponseDto;
        },
        any
      >({
        path: `/api/v3/texts/${requestId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Detect AI-generated text
     *
     * @tags Text [Public]
     * @name TextsPublicControllerGetUniquenessPercentages
     * @summary Detect AI-generated text
     * @request POST:/api/v1/texts/ai-detects
     * @secure
     */
    textsPublicControllerGetUniquenessPercentages: (data: BodyGetTextUniquePercentageDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: MessageRequestResponseDto;
        },
        any
      >({
        path: `/api/v1/texts/ai-detects`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get my Language
     *
     * @tags Languages
     * @name LanguagesControllerGetMyLanguage
     * @summary Get my Language
     * @request GET:/api/v2/languages/my
     * @secure
     */
    languagesControllerGetMyLanguage: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LanguageResponseDto;
        },
        any
      >({
        path: `/api/v2/languages/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get Languages
     *
     * @tags Languages [PUBLIC]
     * @name LanguagesPublicControllerGetLanguages
     * @summary Get Languages
     * @request GET:/api/v1/languages
     * @secure
     */
    languagesPublicControllerGetLanguages: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LanguageResponseDto[];
        },
        any
      >({
        path: `/api/v1/languages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create Language
     *
     * @tags Languages [ADMIN]
     * @name LanguagesAdminControllerCreate
     * @summary Create Language
     * @request POST:/api/v3/languages
     * @secure
     */
    languagesAdminControllerCreate: (data: BodyCreateLanguageDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LanguageResponseDto;
        },
        any
      >({
        path: `/api/v3/languages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Create Language
     *
     * @tags Languages [ADMIN]
     * @name LanguagesAdminControllerUpdate
     * @summary Create Language
     * @request PATCH:/api/v3/languages/{languageCode}
     * @secure
     */
    languagesAdminControllerUpdate: (languageCode: string, data: BodyUpdateLanguageDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: LanguageResponseDto;
        },
        any
      >({
        path: `/api/v3/languages/${languageCode}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages [ADMIN]
     * @name LanguagesAdminControllerDelete
     * @summary Delete Language
     * @request DELETE:/api/v3/languages/{languageCode}
     * @secure
     */
    languagesAdminControllerDelete: (languageCode: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v3/languages/${languageCode}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Create chat
     *
     * @tags Chats
     * @name ChatsControllerCreate
     * @summary Create chat
     * @request POST:/api/v2/chats
     * @secure
     */
    chatsControllerCreate: (data: BodyCreateChatDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: ChatResponseDto;
        },
        any
      >({
        path: `/api/v2/chats`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update chat
     *
     * @tags Chats
     * @name ChatsControllerUpdate
     * @summary Update chat
     * @request PATCH:/api/v2/chats/{chatId}
     * @secure
     */
    chatsControllerUpdate: (chatId: string, data: BodyUpdateChatDto, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: ChatResponseDto;
        },
        any
      >({
        path: `/api/v2/chats/${chatId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get chats
     *
     * @tags Chats
     * @name ChatsControllerGet
     * @summary Get chats
     * @request GET:/api/v2/chats/{chatId}
     * @secure
     */
    chatsControllerGet: ({ chatId, ...query }: ChatsControllerGetParams, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: ChatDetailedResponseDto;
        },
        any
      >({
        path: `/api/v2/chats/${chatId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name ChatsControllerDelete
     * @request DELETE:/api/v2/chats/{chatId}
     * @secure
     */
    chatsControllerDelete: (chatId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v2/chats/${chatId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Pin chat
     *
     * @tags Chats
     * @name ChatsControllerPin
     * @summary Pin chat
     * @request PATCH:/api/v2/chats/{chatId}/pin
     * @secure
     */
    chatsControllerPin: (chatId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: ChatResponseDto;
        },
        any
      >({
        path: `/api/v2/chats/${chatId}/pin`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Unpin chat
     *
     * @tags Chats
     * @name ChatsControllerUnpin
     * @summary Unpin chat
     * @request PATCH:/api/v2/chats/{chatId}/unpin
     * @secure
     */
    chatsControllerUnpin: (chatId: string, params: RequestParams = {}) =>
      this.http.request<
        {
          data?: ChatResponseDto;
        },
        any
      >({
        path: `/api/v2/chats/${chatId}/unpin`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get chats
     *
     * @tags Chats
     * @name ChatsControllerGetList
     * @summary Get chats
     * @request GET:/api/v2/chats/list
     * @secure
     */
    chatsControllerGetList: (params: RequestParams = {}) =>
      this.http.request<
        {
          data?: ChatResponseDto[];
        },
        any
      >({
        path: `/api/v2/chats/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
