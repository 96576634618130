import { createEffect, createEvent, createStore, sample } from 'effector'
import { detectAiQuery, humanizeQuery, rehumanizeQuery } from './queries'

// Humanizer
export const humanizeFx = createEffect(
    async ({ text, isEnhancedModel }: { text: string; isEnhancedModel: boolean }) => 
      humanizeQuery(text, isEnhancedModel)
  )

export const rehumanizeFx = createEffect(
    async ({ text, isEnhancedModel }: { text: string; isEnhancedModel: boolean }) => 
      rehumanizeQuery(text, isEnhancedModel)
  )
  
export const $humanizedText = createStore<string>('')
export const humanizedTextEvent = createEvent<string>()

sample({
    clock: humanizeFx.doneData,
    fn: (clk: any) => clk,
    target: humanizedTextEvent,
})

sample({
    clock: rehumanizeFx.doneData,
    fn: (clk: any) => clk,
    target: $humanizedText,
})

sample({
    clock: humanizedTextEvent,
    target: $humanizedText,
})
//

export const aiDetectFx = createEffect(detectAiQuery)
export const $aiDetect = createStore<any>([])
export const aiDetectEvent = createEvent<any>()

sample({
    clock: aiDetectFx.doneData,
    fn: (clk: any) => clk,
    target: aiDetectEvent,
})

sample({
    target: $aiDetect,
    clock: aiDetectEvent,
})
///
/// redetect

export const $aiReDetect = createStore<any>([])
export const aiReDetectHandler = createEvent<any>()
export const aiReDecectFx = createEffect(detectAiQuery)

sample({
    clock: aiReDetectHandler,
    target: $aiReDetect,
})

sample({
    clock: aiReDecectFx.doneData,
    fn: (clk: any) => clk,
    target: aiReDetectHandler,
})


//
// input val

export const $humanizerInputValue = createStore<string>('')
export const humanizerInputHandler = createEvent<string>()

sample({
    clock: humanizerInputHandler,
    target: $humanizerInputValue,
})

///
