import { createEffect, createEvent, createStore, sample } from 'effector'
import {
    chatListQuery,
    deleteChatQuery,
    getMessages,
    messageQuery,
    remessageQuery,
    newChatQuery,
    pinChat,
    renameChatQuery,
    unPinChat,
} from './queries'

export const chatListHandler = createEvent<any>()
export const chatListFx = createEffect(chatListQuery)
export const $chatList = createStore<any>([]).on(
    chatListFx.doneData,
    (_store, res) => [..._store, res]
)

sample({
    clock: chatListFx.doneData,
    fn: (clk) => clk,
    target: chatListHandler,
})

sample({
    clock: chatListHandler,
    target: $chatList,
})

//
//create chat

export const createChatHandler = createEvent<any>()
export const createChatFx = createEffect(newChatQuery)
export const $createStore = createStore<any>([]).on(
    chatListFx.doneData,
    (_store, res) => res
)

sample({
    clock: createChatFx.doneData,
    fn: (clk: any) => clk,
    target: createChatHandler,
})

type MessagePayload = {
    text: string
    chatId: string
}

export const $messageResponse = createStore<any>(null)
export const messageResponseHandler = createEvent<MessagePayload>()
export const sendMessageFx = createEffect(messageQuery)
export const resendMessageFx = createEffect(remessageQuery)
export const $chatInput = createStore<string>('')
export const chatInputHandler = createEvent<string>()

sample({
    clock: sendMessageFx.doneData,
    fn: (clk: any) => clk,
    target: messageResponseHandler,
})

sample({
    clock: resendMessageFx.doneData,
    fn: (clk: any) => clk,
    target: messageResponseHandler,
})

sample({
    clock: chatInputHandler,
    target: $chatInput,
})
export const $activeChat = createStore<Record<string, string>>({})
export const activeChatEvent = createEvent<any>()

sample({
    clock: activeChatEvent,
    target: $activeChat,
})


export const chatMessagesEvent = createEvent<any>()
export const chatMessagesFx = createEffect(getMessages)
export const $chatMessages = createStore<any>({}).on(
    chatMessagesFx.doneData,
    (_store, res: any) => res
)

sample({
    fn: (clk: any) => clk,
    clock: chatMessagesFx.doneData,
    target: chatMessagesEvent,
})

export const $deleteChat = createStore<any>(null)
export const deleteChatFx = createEffect(deleteChatQuery)
export const deleteChatEvent = createEvent<any>()

sample({
    fn: (clk: any) => clk,
    target: deleteChatEvent,
    clock: deleteChatFx.doneData,
})

export const pinMessageFx = createEffect(pinChat)
export const upPinMessageFx = createEffect(unPinChat)

export const renameChatFx = createEffect(renameChatQuery)
