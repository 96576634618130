import React, { useEffect, useState } from 'react'
import ReactGA from "react-ga4";
import styles from './style.module.scss'
import {ReactComponent as CheckIcon} from './assets/checkIcon.svg'
import {ButtonPrimary, SaleBadge} from '@components'
import {ReactComponent as CrownIcon} from './assets/Crown.svg'
import {useUnit} from 'effector-react'
import {
    $pricing,
    pricingFx,
    subscribeFx,
} from '../../../features/pricing/model'
import RevolutCheckout from '@revolut/checkout'
import {$user, $userLanguage} from '../../../features/app/model'
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom";
import { SubscriptionResponseDto } from '../../../http/Api';

const PricingItem: React.FC<{
    title: string
    price: string
    id: string
    features: string[]
    colorScheme: 'orange' | 'basic'
    discountEnabled: boolean
    period: string
    currency: string
}> = ({
          title,
          price,
          id,
          period,
          colorScheme,
          discountEnabled,
          features,
          currency,
      }) => {
    const currLang = useUnit($userLanguage)
    const user = useUnit($user)
    const subscribeQuery = useUnit(subscribeFx)
    const {t} = useTranslation()
    const navigate = useNavigate()

    const makeRequest = async () => {
        ReactGA.event({
            category: "InitiateCheckout",
            action: "InitiateCheckout",
            label: "InitiateCheckout",
            value: 0,
            nonInteraction: true,
            transport: "xhr",
        });

        subscribeQuery(id).then(async (res: any) => {
            const {payWithPopup} = await RevolutCheckout(
                res.token,
                process.env.REACT_APP_REVOLUT_TYPE as 'sandbox' | 'prod' | 'dev'
            )

            payWithPopup({
                onSuccess() {
                    ReactGA.event({
                        category: "Purchase",
                        action: "Purchase",
                        label: "Purchase",
                        value: res.value,
                        nonInteraction: true,
                        transport: "xhr",
                    });
                    window.alert(t('pricing.alert.thanks'))
                },
                onError(error) {
                    window.alert(`${t('pricing.alert.error')} ${error}`)
                },

                savePaymentMethodFor: 'merchant',
            })
        })
    }

    const renderCheckList = () =>
        features.length &&
        features.map((checkCaption) => (
            <div className={styles.PricingItem__checkCaptionContainer}>
                <div className={styles.PricingItem__checkIconContainer}>
                    <CheckIcon/>
                </div>
                <p>{checkCaption}</p>
            </div>
        ))

        return (
            <div className={`${styles.PricingItem} ${styles[`PricingItem__${colorScheme}`]}`}>
                <div className={styles.PricingItem__discountWrapper}>
                    <h2>{title}</h2>
                    {discountEnabled && (
                        <div className={styles.PricingItem__discount}>
                            <CrownIcon/>
                            <span className={styles.PricingItem__discount_text}>
                                {t('pricing.popular')}
                            </span>
                        </div>
                    )}
                </div>
                <div className={styles.PricingItem__priceContainer}>
                    <span className={styles.PricingItem__currentPrice}>
                        <span className={styles.PricingItem__priceValue}>{price}</span>
                        <span className={styles.PricingItem__pricePeriod}>
                            {currency} / {period === 'month' ? t('pricing.month') : t('pricing.year')}
                        </span>
                    </span>
                    <span className={styles.PricingItem__originalPrice}>
                        <span className={styles.PricingItem__priceValue}>
                            {Math.round(Number(price) * 2.5)}
                        </span>
                        <span className={styles.PricingItem__pricePeriod}>
                            {currency} / {period === 'month' ? t('pricing.month') : t('pricing.year')}
                        </span>
                    </span>
                </div>
                {renderCheckList()}
                <div
                className={styles.PricingItem__button}
                style={{marginTop: 'auto'}}
            >
                <ButtonPrimary
                    className={`${styles.PricingItem__buttonWrapper} ${styles[`PricingItem__buttonWrapper__${colorScheme}`]}`}
                    colorScheme={colorScheme}
                    textAlign="center"
                    onClick={() => {
                        if (localStorage.getItem('token'))
                            makeRequest()
                        else navigate("/auth")
                    }}
                >
                    <div className={styles.PricingItem__button_text}>
                        {t('pricing.getAccess')}
                    </div>
                </ButtonPrimary>
            </div>
        </div>
    )
}
    

export const PricingBlock: React.FC = () => {
    const user = useUnit($user)
    const currLang = useUnit($userLanguage)
    const [data, getSubscriptions] = useUnit([
        $pricing,
        pricingFx,
        pricingFx.pending,
    ]);

    const [subscriptionsCurrent, setSubscriptionsCurrent] = useState<SubscriptionResponseDto[]>([]);
    const [period, setPeriod] = useState<'month' | 'year'>('month');

    useEffect(() => {
        console.log(localStorage.getItem('i18nextLng'))

        if (localStorage.getItem('i18nextLng') !== null) {
            getSubscriptions({
                currencyCode:
                    localStorage.getItem('i18nextLng') === 'en' ? 'USD' : 'SEK',
                //@ts-ignore
                languageCode:
                    localStorage.getItem('i18nextLng') === 'en' ? 'EN' : 'SV',
            })
        }
    }, [currLang, getSubscriptions]);

    const {t} = useTranslation();

    useEffect(() => {
        const subscriptions = (data as SubscriptionResponseDto[])
            .filter(s => s.isPricingPage && s.period === period)
            .sort((a, b) => (a.amount || 0) - (b.amount || 0)); // Sort by price
        setSubscriptionsCurrent(subscriptions);
    }, [data, period]);

    const pricingItemClass = (currentPeriod: 'month' | 'year', itemPeriod: 'month' | 'year') => {
        return [styles.Pricing__item, currentPeriod == itemPeriod ? styles.Pricing__red : styles.Pricing__white].join(' ');
    }

    return (
        <div className={`app-container ${styles.PricingBlock}`}>
            <div className={styles.Pricing}>
                <h2 className={styles.Pricing__title}>{t('pricing.title')}</h2>
                <p className={styles.Pricing__plan}>
                    <span onClick={() => setPeriod('month')} className={pricingItemClass(period, 'month')}>
                        {t('pricing.monthly')}
                    </span>
                    <span className={styles.Pricing__delimiter}>/</span>
                    <span onClick={() => setPeriod('year')} className={pricingItemClass(period, 'year')}>
                        {t('pricing.annually')}
                    </span>
                    <span className={styles.Pricing__discount}>{t('pricing.discount')}</span>
                </p>
                <div className={styles.Pricing__guarantee}>
                {t('pricing.guarantee')}
                </div>
                <div className={styles.Pricing__container}>
                    {subscriptionsCurrent?.length
                        ? subscriptionsCurrent.map((el: SubscriptionResponseDto, index: number) => (
                            <PricingItem
                                key={el.subscriptionId}
                                discountEnabled={index === Math.floor(subscriptionsCurrent.length / 2)} // Set middle item as featured
                                title={el.title || ''}
                                price={el.amount?.toString() || ''}
                                id={el.subscriptionId}
                                period={el.period}
                                features={el.features || []}
                                currency={el.currency?.symbol || ''}
                                colorScheme={index === Math.floor(subscriptionsCurrent.length / 2) ? 'orange' : 'basic'}
                            />
                        ))
                        : 'No pricing plans available'}
                </div>
                <div className={styles.Pricing__saleBadgeWrapper}>
                    <SaleBadge message={t('pricing.sale')} />
                </div>
            </div>
        </div>
    )
}
